import { Badge, TableColumn } from "@vilocnv/allsetra-core";
import { formatTimeWithDay } from "app/data/helpers";
import { round } from "lodash";

export const COMMON_REPORT_TABLE_COLUMNS = (
  translator: any,
  isShowKeyCol: boolean
): TableColumn<any>[] => {
  let columns = [
    {
      name: translator("tableHeading.rideType", {
        ns: "tableHeadingsTranslation"
      })
    },
    {
      name: translator("tableHeading.distance", {
        ns: "tableHeadingsTranslation"
      })
    },
    {
      name: translator("tableHeading.rideDuration", {
        ns: "tableHeadingsTranslation"
      })
    }
  ];
  if (isShowKeyCol) {
    columns = [
      {
        name: translator("tableHeading.key", { ns: "tableHeadingsTranslation" })
      },
      ...columns
    ];
  }
  return columns;
};

export const RIDE_MODE_IDENTIFICATION = [
  { label: "None", key: 0 },
  { label: "Business ride", key: 1 },
  { label: "Private ride", key: 2 },
  { label: "Commuting ride", key: 3 }
];

export const getColorForRideMode = (rideMode: number) => {
  const mode = RIDE_MODE_IDENTIFICATION.find((item) => item.key === rideMode);
  switch (mode?.key) {
    case 1:
      return "purple"; // Business ride
    case 2:
      return "info"; // Private ride
    case 3:
      return "success"; // Commuting ride
    case 0:
      return "grey";
    default:
      return "grey"; // Default color for unknown ride mode
  }
};

export const calculateCO2AndMilageTimeDiff = (endTime: any, startTime: any) => {
  const endTimeObj = new Date(endTime);
  const startTimeObj = new Date(startTime);

  const timeDifference = endTimeObj.getTime() - startTimeObj.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const padWithZero = (value: number) => (value < 10 ? `0${value}` : value);

  // Format the output with leading zeros
  const formattedTime = `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;

  return formattedTime;
};

export const COMMON_REPORT_INNER_TABLE_COLUMNS = () => {
  return [
    {
      cell: (row: any) => {
        const mode = RIDE_MODE_IDENTIFICATION.find(
          (item) => item.key === row?.rideMode
        );

        const color: any = getColorForRideMode(row.rideMode);

        return (
          <Badge colorScheme={color ?? "error"}>{mode?.label || "-"}</Badge>
        );
      }
    },
    {
      cell: (row: any) =>
        row?.kilometersDriven
          ? `${round(row?.kilometersDriven, 1)} ${"km"}`
          : "N/A"
    },
    {
      cell: (row: any) => {
        const endTime = formatTimeWithDay(row.endDate);
        const startTime = formatTimeWithDay(row.startDate);

        return endTime !== "Invalid DateTime"
          ? calculateCO2AndMilageTimeDiff(endTime, startTime)
          : "Ongoing";
      }
    }
  ];
};

export const RIDE_MODES = [
  { name: "Business", value: "1" },
  { name: "Commuting", value: "3" },
  { name: "Private", value: "2" }
];
